import HttpService from '../HttpService/HttpService'
import { createPhoneNumberPayload, PhoneNumber, WhatsappTemplate, WhatsappBusinessProfile, WhatsAppBusinessProfileResponse } from '../AccountService/types'
import { SendMessageResponse } from './types'

export default class MetaService {
  private constructor () {
    // Check if an instance already exists and return it
    if (instance) {
      return instance
    }
    // If no instance exists, create one and assign it to the 'instance' variable
    instance = this
  }

  static async getInstance () {
    const metaService = new MetaService()
    return metaService
  }

  async exchangeCodeForToken (code: string) {
    const httpService = await HttpService.getInstance()
    const response = await httpService.post('/api/v1/meta/signup/exchange', { code })
    return response
  }

  async synchronizeWithMeta () {
    const httpService = await HttpService.getInstance()
    const response = await httpService.post('/api/v1/meta/synchronize', {})
    return response
  }

  async synchronizeWhatsappBusinessAccounts () {
    const httpService = await HttpService.getInstance()
    const response = await httpService.post('/api/v1/meta/synchronize/whatsapp/accounts', {})
    return response
  }

  async synchronizeAccountPhoneNumbers (wabaId: string) {
    const httpService = await HttpService.getInstance()
    const response = await httpService.post(`/api/v1/meta/synchronize/whatsapp/accounts/${wabaId}/phone_numbers`, {})
    return response
  }

  async synchronizeMessageTemplates (wabaId: string, phoneNumberId: string) {
    const httpService = await HttpService.getInstance()
    const response = await httpService.post(`/api/v1/meta/synchronize/whatsapp/accounts/${wabaId}/phone_numbers/${phoneNumberId}/message_templates`, {})
    return response
  }

  async createPhoneNumber (wabaId: string, createPhoneNumberPayload: createPhoneNumberPayload) {
    const httpService = await HttpService.getInstance()
    const response = await httpService.post(
      `/api/v1/meta/whatsapp/accounts/${wabaId}/add_phone_number`,
      createPhoneNumberPayload
    )
    return response
  }

  async requestPhonePin (phoneId: string) {
    const httpService = await HttpService.getInstance()
    const response = await httpService.get(`/api/v1/meta/whatsapp/phone_numbers/${phoneId}/verification_code`)
    return response
  }

  async verifyAndRegisterPhone (phoneId: string, pin: string) {
    const httpService = await HttpService.getInstance()
    const response = await httpService.post(
      `/api/v1/meta/whatsapp/phone_numbers/${phoneId}/verify_and_register?verification_code=${pin}`,
      {}
    )
    return response
  }

  async registerPhoneNumber (phoneNumber: PhoneNumber, pin: string): Promise<PhoneNumber> {
    const httpService = await HttpService.getInstance()
    const response = await httpService.post(`/api/v1/meta/synchronize/whatsapp/accounts/${phoneNumber.waba_id}/phone_numbers/${phoneNumber.id}/register`, { pin })
    return response
  }

  async getPhoneHealthStatus (phoneNumber: PhoneNumber): Promise<PhoneNumber> {
    const httpService = await HttpService.getInstance()
    const response = await httpService.get(`/api/v1/meta/synchronize/whatsapp/accounts/${phoneNumber.waba_id}/phone_numbers/${phoneNumber.id}/health_status`)
    return response
  }

  async testMessageTemplate (wabaId: string, phoneNumberId: string, testPhoneNumber: string, messageTemplate: WhatsappTemplate): Promise<SendMessageResponse> {
    const httpService = await HttpService.getInstance()
    const payload = {
      phone_number: testPhoneNumber,
      template: messageTemplate
    }
    const response = await httpService.post(`/api/v1/meta/test/whatsapp/accounts/${wabaId}/phone_numbers/${phoneNumberId}/message_templates`, payload)
    return response
  }

  async getWabaSystemUsers (wabaId: string) {
    const httpService = await HttpService.getInstance()
    const response = await httpService.get(`/api/v1/meta/whatsapp/accounts/${wabaId}/system_users`)
    return response
  }

  async createSystemUser (wabaId: string, systemUserId: string) {
    const httpService = await HttpService.getInstance()
    const payload = { system_user_id: systemUserId }
    const response = await httpService.post(`/api/v1/meta/whatsapp/accounts/${wabaId}/system_users`, payload)
    return response
  }

  async getSubscribedApps (wabaId: string) {
    const httpService = await HttpService.getInstance()
    const response = await httpService.get(`/api/v1/meta/whatsapp/accounts/${wabaId}/subscribed_apps`)
    return response
  }

  async subscribeApp (wabaId: string) {
    const httpService = await HttpService.getInstance()
    const response = await httpService.post(`/api/v1/meta/whatsapp/accounts/${wabaId}/subscribed_apps`, {})
    return response
  }

  async getMediaUrl (mediaId: string) {
    const httpService = await HttpService.getInstance()
    const response = await httpService.get(`/api/v1/meta/media/${mediaId}`)
    return response
  }

  async downloadMediaUrl (mediaId: string, mediaUrl: string) {
    const httpService = await HttpService.getInstance()
    const response = await httpService.post('/api/v1/meta/media/download', { media_url: mediaUrl, media_id: mediaId })
    return response
  }

  async deleteMessageTemplate (wabaId: string, phoneNumberId: string, messageTemplateId: string, messageTemplateName: string): Promise<any> {
    const httpService = await HttpService.getInstance()
    const payload = {
      template_id: messageTemplateId,
      template_name: messageTemplateName
    }
    const response = await httpService.delete(`/api/v1/meta/synchronize/whatsapp/accounts/${wabaId}/phone_numbers/${phoneNumberId}/message_templates`, payload)
    return response
  }

  async getBusinessProfile (wabaId: string, phoneNumberId: string): Promise<WhatsAppBusinessProfileResponse> {
    const httpService = await HttpService.getInstance()
    const response = await httpService.get(`/api/v1/meta/synchronize/whatsapp/accounts/${wabaId}/phone_numbers/${phoneNumberId}/business_profile`)
    return response
  }

  async updateBusinessProfile (wabaId: string, phoneNumberId: string, businessProfile: WhatsappBusinessProfile) {
    const httpService = await HttpService.getInstance()
    const response = await httpService.post(`/api/v1/meta/synchronize/whatsapp/accounts/${wabaId}/phone_numbers/${phoneNumberId}/business_profile`, businessProfile)
    return response
  }

  async shareCreditLine (wabaId: string) {
    const httpService = await HttpService.getInstance()
    const response = await httpService.post(`/api/v1/meta/whatsapp/accounts/${wabaId}/share_credit_line`, {})
    return response
  }

  async verifyCreditLine (wabaId: string) {
    const httpService = await HttpService.getInstance()
    const response = await httpService.post(`/api/v1/meta/whatsapp/accounts/${wabaId}/verify_credit_line`, {})
    return response
  }

  async revokeCreditLine (wabaId: string) {
    const httpService = await HttpService.getInstance()
    const response = await httpService.post(`/api/v1/meta/whatsapp/accounts/${wabaId}/revoke_credit_line`, {})
    return response
  }
}

let instance: MetaService | null = null // Singleton instance
